<template>
  <div>
    <Table
      resource="Collection"
      :actions="actions"
      :items="$store.getters['collection/tableData']"
      :detail="$store.getters['collection/detailData']"
      :fields="$store.getters['collection/tableFields']"
      :pagination="$store.state.collection.tablePagination"
      :keyword="$store.state.collection.tableKeyword"
      :loading="$store.state.collection.loadingTable"
      :filter="$store.state.collection.tableFilter"
      :sort="$store.state.collection.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @delete="deleteData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'created_at', direction: 'desc', label: 'Terbaru', default: true,
        },
        { key: 'created_at', direction: 'asc', label: 'Terlama' },
      ],

      actions: [
        'create',
        'view',
        'update',
        'delete',
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('collection/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('collection/getDetail', id)
    },
    deleteData(id, callback) {
      return this.$store.dispatch('collection/delete', id)
        .then(callback)
    },
    resetDetail() {
      this.$store.commit('collection/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('collection/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('collection/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('collection/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('collection/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('collection/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('collection/SET_TABLE_KEYWORD', val)
    },
  },
}
</script>
